import { createSlice } from '@reduxjs/toolkit'
// import { loginAsync } from './actions'
import { defaultChain } from '_src/utils/chain/chainConfUtil'
import { isAppSupportedNetworkId } from '_src/utils/chain'

const { chainId = '', networkId = '' } = defaultChain()
console.log(defaultChain())
// state数据
console.log(chainId)
let initialState = {
  theme: 'dark',
  lng: '',
  address: '',
  chainId,
  networkId,
  connected: false,
  isSupportNetworkInfo: false,
  isLogin: false,
  lottery: [],
  historyLottery: [],
  userLottery: [],
  round: 0,
  isWin: true,
  isRenew: 0,
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lng = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setConnected: (state, action) => {
      state.connected = action.payload
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setIsSupportNetworkAction: (state, action) => {
      state.isSupportNetworkInfo = action.payload
    },
    setChainId: (state, action) => {
      if (action.payload) {
        const chainId = action.payload
        state.chainId = chainId
      }
    },
    setNetworkId: (state, action) => {
      if (action.payload) {
        const networkId = action.payload
        state.networkId = networkId
      }
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload
    },
    clearGlobalStore: (state) => {
      state.connected = false
      state.chainId = chainId
      state.address = ''
      state.networkId = networkId
      ;(state.isSupportNetworkInfo = false), (state.isLogin = false)
    },
    setLottery: (state, action) => {
      state.lottery = action.payload
    },
    setHistoryLottery: (state, action) => {
      state.historyLottery = action.payload
    },
    setUserLottery: (state, action) => {
      state.userLottery = action.payload
    },
    setRound: (state, action) => {
      state.round = action.payload
    },
    setIsWin: (state, action) => {
      state.isWin = action.payload
    },
    setIsRenew: (state, action) => {
      state.isRenew = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/setNetworkId'),
      (state, action) => {
        // 不支持网络更改
        if (isAppSupportedNetworkId(action.payload)) {
          state.isSupportNetworkInfo = false
        } else {
          state.isSupportNetworkInfo = true
        }
      }
    )
  },
})

export const {
  setLanguage,
  setConnected,
  setIsSupportNetworkAction,
  setAddress,
  setChainId,
  setNetworkId,
  clearGlobalStore,
  setIsLogin,
  setLottery,
  setHistoryLottery,
  setUserLottery,
  setRound,
  setIsWin,
  setIsRenew,
} = globalSlice.actions

export default globalSlice.reducer
