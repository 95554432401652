import React, { Suspense } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { message } from 'antd'
import { PageLayout, LoadingLayout } from '_src/layouts'
import privateRoute from './privateRoute'
import routes from './routes'
import { initTheme } from '_src/themes'

message.config({
	top: 80,
})
function App() {
	initTheme('light')
	return (
		<BrowserRouter>
			<PageLayout>
				<Suspense fallback={<LoadingLayout />}>
					<Switch>{privateRoute(routes())}<Redirect to={'/home'}></Redirect></Switch>
				</Suspense>
			</PageLayout>
		</BrowserRouter>
	)
}

export default App
