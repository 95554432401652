import { createSlice } from '@reduxjs/toolkit'

// state数据
let initialState = {
	activeUrl: ['/'],
}

export const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setActiveUrl: (state, action) => {
			state.activeUrl = action.payload
		},
	},
})

export const { setActiveUrl } = menuSlice.actions

export default menuSlice.reducer
