import util from 'util'
import Web3 from 'web3'

export function initWeb3(provider) {
	const web3 = new Web3(provider)
	
	web3.eth.extend({
		methods: [
			{
				name: 'chainId',
				call: 'eth_chainId',
				outputFormatter: web3.utils.numberToHex,
			},
		],
	})

	return web3
}
export function createSendRpc(currentProvider) {
	return util.promisify(currentProvider.send).bind(currentProvider)
}
export function transformNetworkId(networkId) {
	// 只要10进制
	if (networkId) {
		const id = Web3.utils.isHexStrict(networkId) ? Web3.utils.hexToNumber(networkId) : networkId
		return id
	}

	return networkId
}

export function transformChainId(chainId) {
	// 只要10进制
	if (chainId) {
		const id = Web3.utils.isHexStrict(chainId) ? chainId : Web3.utils.numberToHex(chainId)
		return id
	}

	return chainId
}
