import lightTheme from '_src/themes/light'
import darkTheme from '_src/themes/dark'

import store from '../store'
import { setTheme } from '_src/store/global/actions'

const initTheme = (t) => {
	let htmlNode = document.documentElement
	const object = t == 'light' ? lightTheme : darkTheme

	store.dispatch(setTheme('dark'))

	for (const key in object) {
		htmlNode.style.setProperty(key, object[key])
	}
}

export { initTheme }
