import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { matchRoutes } from 'react-router-config'
import classNames from 'classnames'
import { Layout } from 'antd'
import { initTheme } from '_src/themes'
import { useAppSelector, useAppDispatch } from '_src/store/hooks'
import Cookies from 'js-cookie'
import routes from '_src/routes'
import { GlobalWallet } from '_src/hooks/useWallet'
import { setWeb3, setProvider, clearWalletStore } from '_src/store/wallet/reducer'
import {
  setConnected,
  setAddress,
  clearGlobalStore,
  setRound,
  setLottery,
  setHistoryLottery,
  setUserLottery,
} from '_src/store/global/reducer'
import { initWeb3, transformNetworkId, transformChainId } from '_src/utils/w3'
import { setNetworkIdAction, setChainIdAction } from '_src/store/global/actions'
import { web3Modal } from '_src/wallet/wallet' // 配置项
import { setActiveUrl } from '_src/store/menu/reducer'
import { publicSelectChain } from '../../utils/chain/chainConfFunc'
import { getChainId, defaultChain } from '../../utils/chain/chainConfUtil'
import { flattenArray, findArrayParents } from '../../utils'
import './index.less'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Mobel from '../../components/Modal'
import { imgUrl } from '_src/utils/staticUrl'
// import { setIsSupportNetworkAction } from '../../store/global/reducer'
import { GetContractAddressAbi } from '_src/contracts/common'

const { Content } = Layout

export default function PageLayout({ children, className }) {
  const { pathname } = useLocation()
  const { theme, address, chainId, isSupportNetworkInfo, connected, isRenew } = useAppSelector((store) => store.global)
  console.log(useAppSelector((store) => store.global))
  const { web3 } = useAppSelector((store) => store.wallet)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const routeArray = flattenArray(routes())
  const [visible, setVisible] = useState(false)
  const [show, setShow] = useState(true)
  const countDownTimer = useRef()

  // 导航操作 start
  const handleSetActiveUrl = (pathname) => {
    // 设置导航焦点
    const marchRoutes = matchRoutes(routeArray, pathname)
    const path = marchRoutes[0]?.match?.path || ''

    if (path) {
      const urls = findArrayParents(routes(), (data) => data.path === path)
      urls && dispatch(setActiveUrl(urls))
    }
  }
  // 切链
  const handleChange = () => {
    changeChain(getChainId())
    if (address) {
      publicSelectChain(chainId)
    }
  }
  const changeChain = async (chainId) => {
    if (address) {
      // 已经连接钱包操作
      const result = await publicSelectChain(chainId).catch((error) => {
        console.log(error)
      })
      if (result) {
        setVisible(false)
      }
    } else {
      dispatch(setChainIdAction(chainId))
    }
  }
  // 关闭切链弹窗
  // const handleClose = () => {
  // 	dispatch(setIsSupportNetworkAction(false))
  // 	setVisible(false)
  // }
  // 断开连接
  const resetApp = async () => {
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close()
    }
    await web3Modal.clearCachedProvider()
    // 清空为默认值的操作
    dispatch(clearGlobalStore())
    dispatch(clearWalletStore())
  }
  // 钱包连接
  const onConnect = async () => {
    try {
      const provider = await web3Modal.connect().catch((error) => {
        console.log('error', error)

        // 清空为默认值的操作
        dispatch(clearGlobalStore())
        dispatch(clearWalletStore())
        return new Promise((resolve) => {
          resolve({ connected: false })
        })
      })
      await subscribeProvider(provider)

      await provider.enable()
      const web3 = initWeb3(provider)

      dispatch(setWeb3(web3)) // store 中存储 web3 localStorage 不自动同步

      const accounts = await web3.eth.getAccounts()
      const [address] = accounts
      dispatch(setAddress(address)) // store 中存储 address localStorage 自动同步
      const chainId = await web3.eth.chainId()
      dispatch(setChainIdAction(chainId)) // store 中存储 chainId localStorage 自动同步
      dispatch(setProvider(provider)) // store 中存储 provider localStorage 不自动同步

      const networkId = await web3.eth.net.getId()
      dispatch(setNetworkIdAction(networkId))
      // await dispatch(loginAsync())
      dispatch(setConnected(true)) // store 中存储 connected localStorage 自动同步

      return new Promise((resolve) => {
        resolve({ connected: true })
      })
    } catch (error) {
      return new Promise((resolve, reject) => {
        reject({ connected: false })
      })
    }
  }
  // 钱包监听
  const subscribeProvider = (provider) => {
    if (!provider.on) {
      return
    }

    provider.on('close', () => resetApp())
    provider.on('accountsChanged', (accounts) => {
      //TODO 账户全都断开 为啥不调用  provider.on("close") ???
      if (accounts.length > 0) {
        const [address] = accounts
        dispatch(setAddress(address))
        // dispatch(loginAsync())
      } else {
        resetApp()
      }

      Cookies.remove('access_token')
    })
    provider.on('chainChanged', (chainId) => {
      // chainid   MetaMask回的是 16进制，walletconnect 返回的的是 10 进制
      const tChainId = transformChainId(chainId)
      console.log(tChainId)
      if (tChainId) {
        dispatch(setChainIdAction(tChainId)) // store 中存储 chainId localStorage 自动同步
      }
      const tNetworkId = transformNetworkId(chainId)
      if (tNetworkId) {
        dispatch(setNetworkIdAction(tNetworkId)) // store 中存储 networkId localStorage 自动同步
        // dispatch(loginAsync())
      }
    })
    // provider.on('disconnect', (res) => {
    // 	console.log(res)
    // })
  }
  const walletFunc = {
    walletConnect: onConnect, // 连接钱包
    walletDisconnect: resetApp, // 断开连接
  }

  const getLotteryInfo = async () => {
    const contractAddressAbi = GetContractAddressAbi({ type: 'lottery', chainId })
    const contract = new web3.eth.Contract(contractAddressAbi.abi, contractAddressAbi.address)

    let id = await contract.methods.currentLotteryId().call()
    dispatch(setRound(id))

    let res = await contract.methods.viewLottery(id).call()
    dispatch(setLottery(res))
    let historyRes = await contract.methods.viewLottery(res[0] == 3 ? id : id - 1).call()
    dispatch(setHistoryLottery(historyRes))

    if (connected) {
      let userRes = await contract.methods.viewUserInfoForLotteryId(address, id, 0, '99999').call()
      dispatch(setUserLottery(userRes))
    }

    countDownTimer.current = setTimeout(() => {
      getLotteryInfo()
    }, 1000 * 60)
  }

  useEffect(() => {
    history.listen((historyLocation) => {
      // 每次路由变化都会执行这个方法
      const historyItem = routeArray.find((item) => item.path === historyLocation.pathname)
      if (historyItem) {
        const { hideFooter = false } = historyItem
        setShow(hideFooter)
      }

      handleSetActiveUrl(historyLocation.pathname)

      window && window.scrollTo(0, 0)
    })
  }, [history])
  useEffect(() => {
    //  设置导航焦点
    handleSetActiveUrl(pathname)
    // 钱包
    if (web3Modal?.cachedProvider) {
      onConnect()
    }
    initTheme(theme || 'dark')
  }, [])

  useEffect(() => {
    if (!connected && !web3) {
      let web3 = initWeb3(defaultChain().rpc_url)
      // https://rpc.ankr.com/bsc_testnet_chapel
      dispatch(setWeb3(web3)) // store 中存储 web3 localStorage 不自动同步
    }

    if (web3) {
      getLotteryInfo()
    }
  }, [web3, connected, isRenew])
  return (
    <GlobalWallet.Provider value={walletFunc}>
      <Layout className={classNames('page-layout', className)}>
        <Header />
        <Content className={classNames('cust-content')}>{children}</Content>
        {!show && <Footer />}
      </Layout>
      <Mobel
        title="Switch Network"
        visible={isSupportNetworkInfo || visible}
        // handleClose={handleClose}
        width={600}
      >
        <div className="modal_content">
          <div className="modal_content_prompt">
            <img className="" src={imgUrl('bnbChain.png')} alt="" />
          </div>
          <div className="tips">Switch to BNB Smart Chain </div>
          <div className="btn" onClick={handleChange}>
            Switch Network
          </div>
        </div>
      </Mobel>
    </GlobalWallet.Provider>
  )
}
