/* eslint-disable no-unused-vars */
import React from 'react'
import { Route } from 'react-router-dom'

import { flattenArray } from '_src/utils'

const privateRoute = (routes = []) => {
	const routesArray = flattenArray(routes)
	return routesArray.map((route) => (
		<Route key={route.path} exact={route.exact} path={route.path} component={React.lazy(route.component)} />
	))
}

export default privateRoute
