import { chainList, defaultNetwork, defaultChain } from './chainConfUtil'

const configChainList = chainList()

/**
 * @description: 判断当前链id 平台是否支持
 * @param {Number} chainId
 * @return {Boolean}
 */
export function isAppSupportedChainId(chainId) {
	const flag = configChainList.some((item) => item.chainId == chainId)
	return flag
}

/**
 * @description: 判断当前网络 平台是否支持
 * @param {Number} networkId
 * @return {Boolean}
 */
export function isAppSupportedNetworkId(networkId) {
	const flag = configChainList.some((item) => item.networkId == networkId)
	// console.log(networkId,configChainList,flag,'net')
	return flag
}

/**
 * @description: 判断当前链是不是平台主网络
 * @return {Number}
 */
export function isMainNetwork(networkId) {
	if (networkId) {
		const networkItem = defaultNetwork()
		return networkItem.networkId == networkId
	}
	return false
}

/**
 * @description: 判断当前链是不是平台主链
 * @return {Number}
 */
export function isMainChain(chainId) {
	if (chainId) {
		const chainItem = defaultChain()
		// console.log(chainItem.chainId, chainId, 'chanInd')

		return chainItem.chainId == chainId
	}
	return false
}
