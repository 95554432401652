import { createSlice } from '@reduxjs/toolkit'

// state数据
let initialState = {
	web3: null,
	provider: null,
}

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWeb3: (state, action) => {
			state.web3 = action.payload
		},
		setProvider: (state, action) => {
			state.provider = action.payload
		},
		clearWalletStore: (state) => {
			state.web3 = null
			state.provider = null
		},
	},
})

export const { setWeb3, setProvider, clearWalletStore } = walletSlice.actions

export default walletSlice.reducer
