import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import './styles/index.less'
import './i18n'

class Root extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<App />
			</Provider>
		)
	}
}

ReactDOM.render(<Root />, document.getElementById('root'))

//  实时刷新
if (module.hot) {
	module.hot.accept()
}

// 移动端调试工具
const { NODE_ENV } = process.env
/* vconsole 生产环境开启需要单独写 */
if (NODE_ENV != 'production' && document.documentElement.clientWidth < 750) {
	const vconsole = require('vconsole')
	new vconsole()
}
