import React from 'react'
import { Modal as AntdModal } from 'antd'
import Icon from '_src/components/Icon'
import './index.less'
const Mobel = ({ title, visible, width, handleClose, footer = null, ...props }) => {
	return (
		<AntdModal
			footer={footer}
			title={title}
			open={visible}
			width={width}
			maskStyle={{ backdropFilter: 'blur(4px)' }}
			destroyOnClose={true}
			className="modal"
			closeIcon={
				<Icon className='closeButton' type='icon-close' onClick={handleClose} />
			}
		>
			{props.children}
		</AntdModal>
	)
}

export default Mobel
