import { getChainsAddNetWork } from './chainConfUtil'
import Web3 from 'web3'
import store from '_src/store'

import { chainInfo } from './chainConfUtil'

const isNeedWalletAddChain = (switchError) => {
	if (switchError) {
		// This error code indicates that the chain has not been added to MetaMask.
		if (switchError?.code === 4902 || switchError?.code === -32603) return true
		// This error code indicates that the chain has not been added to WallectConnect.
		// TODO 无法拿到错误的状态 后期需要优化

		if (switchError?.message && switchError.message.includes('wallet_addEthereumChain')) return true
	}

	return false
}

export const publicSelectChain = async (chainId) => {
	// web3 都要从 store 统一入口 中取
	console.log(chainId)
	const { wallet } = store.getState()
	const { web3 = '' } = wallet
	if (!web3) return
	try {
		await web3.currentProvider.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId }],
		})

		// TODO 无法拿到成功的状态
		return new Promise((resolve) => {
			resolve(chainId)
		})
	} catch (switchError) {
		if (isNeedWalletAddChain(switchError)) {
			try {
				const addNetWorkItem = getChainsAddNetWork(chainId)

				await web3.currentProvider.request({
					method: 'wallet_addEthereumChain',
					params: [addNetWorkItem],
				})

				// TODO 无法拿到成功的状态
				return new Promise((resolve) => {
					resolve(chainId)
				})
			} catch (addError) {
				// throw addError;
				console.log(addError, '1212')
				return new Promise((resolve, reject) => {
					reject(false)
				})
			}
		}

		// User rejected the request.
		if (switchError?.code === 4001) {
			return new Promise((resolve, reject) => {
				reject(false)
			})
		}
	}
}

/**
 * @description: 获取链跳转链接（ 正式环境和测试环境通用 ）
 * @param {Number} chainId 当前环境链的id
 * @return {String}
 */
export function getChainUrl(chainId) {
	if (chainId) {
		chainId = Web3.utils.toHex(chainId)
		return chainInfo[chainId] && chainInfo[chainId].chainUrl
	}

	return ''
}

/**
 * @description: 获取链区块浏览器跳转链接（ 正式环境和测试环境通用 ）
 * @param {Number} chainId 当前环境链的id
 * @return {String}
 */
export function getChainBlockChainBrowserUrl(chainId) {
	const chain = getChainUrl(chainId)

	if (chain) {
		return `${chain}tx/`
	}
	return ''
}
