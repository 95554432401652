import React, { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import './index.less'

const Footer = () => {
	const { lng } = useAppSelector((store) => store.global)
	useEffect(() => {

	}, [lng])
	return (
		<div id="footer">
			
		</div>
	)
}

export default Footer
