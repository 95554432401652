import { isDev } from '../index'
import { chainInfo, chainsAddNetWork } from '_src/config/chainConfig.json'

// Eth
// 正式链     链ID 0x1     网络ID 1
// 测试链是   链ID 0x4     网络ID 4

// Polygon
// 正式链  链ID 0x89      网络ID 137
// 测试链  链ID 0x13881   网络ID 80001

// Binance
// 正式链  链ID 0x38       网络ID 56
// 测试链  链ID 0x61       网络ID 97


export const DEFAULT_NET = ['0x1', '0x2', '0x3', '0x4']

const chainIdArrForProd = ['0x38'] // 正式环境
const chainIdArrForDev = ['0x61'] // 测试环境

// 正式环境链信息
export const onlineChains = chainIdArrForProd.map((chainId) => {
	return chainInfo[chainId]
})
// 测试环境链信息
export const devChains = chainIdArrForDev.map((chainId) => {
	return chainInfo[chainId]
})

export { chainInfo, chainsAddNetWork } // 总的链配置
// export const chainIdArrRunning = isDev() ? chainIdArrForDev : chainIdArrForProd;
const chainsItems = isDev() ? devChains : onlineChains

// 返回测试环境和正式环境的所有链信息（ 隐藏 ETH ）
export const chainList = () => {
	// 隐藏 ETH
	const hideItem = []
	const items = chainsItems.filter((item) => !hideItem.includes(item.chainId))
	return items
}

// 返回测试环境和正式环境的默认链信息
export const defaultChain = () => {
	// 默认链
	const defaultItem = ['0x38', '0x61']
	const items = chainsItems.filter((item) => defaultItem.includes(item.chainId))
	return items[0] || {}
}

// 返回测试环境和正式环境的默认网络信息
export const defaultNetwork = () => {
	// 默认网络 
	const defaultItem = [56, 97]
	const items = chainsItems.filter((item) => defaultItem.includes(item.networkId))
	return items[0] || {}
}

// 添加网络
export const getChainsAddNetWork = (chainId) => {
	return chainsAddNetWork[chainId] || ''
}

//
export const getChainId = () => {
	return isDev() ? '0x61' : '0x38'
}
