// 数字千分位
export const newNumberThousands = (num) => {
  num =
    num && num.toString().split('.')[1] && num.toString().split('.')[1].length > 3
      ? num.toString().substring(0, num.toString().indexOf('.') + 4)
      : num
  if (num && Number(num) >= 1000) {
    var numberList = num.toString().split('.')
    if (numberList.length > 1) {
      num = numberList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + numberList[1]
    } else {
      num = numberList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return num
  } else {
    return num
  }
}

export const numberThousands = (num) => {
  num =
    num && num.toString().split('.')[1] && num.toString().split('.')[1].length > 3
      ? num.toString().substring(0, num.toString().indexOf('.') + 4)
      : num
  return num
  // if (num && Number(num) >= 1000) {
  // 	var numberList = num.toString().split('.')
  // 	if (numberList.length > 1) {
  // 		num = numberList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + numberList[1]
  // 	} else {
  // 		num = numberList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // 	}
  // 	return num
  // } else {
  // 	return num
  // }
}

/**
 * @description: 地址简单显示（ 正式环境和测试环境通用 ）
 * @param {Number} address 16进制的地址
 * @param {Number} limitLength
 * @return {String}
 */
export function showShortAddress(address, start = 6, end = 4, limitLength = 40) {
  if (address) {
    if (address.startsWith('0x') || address.length >= limitLength) {
      address = address ? address.slice(0, start) + '...' + address.substr(address.length - end, end) : ''
    }
  }

  return address
}

// 字符串中间缩略显示
export function showShortString(string, start = 4, end = 4) {
  if (string) {
    if (string.length > start + end) {
      string = string.slice(0, start) + '...' + string.substr(string.length - end, end)
    }
  }
  return string
}
// 字符串末尾缩略显示
export function showShortStringStart(string, leng) {
  if (string) {
    if (string.length > leng) {
      string = string.slice(0, leng) + '...'
    }
  }
  return string
}
