import store from '_src/store'

export function GetContractAddressAbi({ type, chainId }) {
	const contractConfig = require('../config/contractConfig.json')
	const contractObject = contractConfig[process.env.REACT_APP_ENV] // 区分环境
	const contractConfigList = contractObject[type]
	return {
		address: contractConfigList[0][chainId],
		abi: require(`./${type}/abi.json`),
	}
}

// 合约对象  使用钱必须登录钱包
/**
 * @description: 创建合约对象
 * @param {config}  配置参数
 * @return {Promise<T>}
 */
export function InftContract(config) {
	// config: {
	//   type,
	//   version,
	//   contractAddress,
	// }

	// web3 chainId 都要从 store 统一入口 中取
	const { global, wallet } = store.getState()
	const { chainId = '' } = global
	const { web3 = '' } = wallet

	return new Promise((resolve, reject) => {
		if (web3 && chainId) {
			const { abi, address } = GetContractAddressAbi({ ...config, chainId })
			try {
				if (abi && address) {
					const contract = new web3.eth.Contract(abi, address) // abi 文件, address: 配置的地址
					resolve(contract)
				} else {
					reject(new Error('Contract not supported the chainId:' + chainId))
				}
			} catch (error) {
				console.log(error)
				reject(error)
			}
		} else {
			reject(new Error('Contract creation failed'))
		}
	})
}
