import Web3Modal from 'web3modal'
import WalletConnect from '@walletconnect/web3-provider'
const providerOptions = {
	walletconnect: {
		package: WalletConnect,
		options: {
			qrcodeModalOptions: {
				mobileLinks: ['metamask', 'argent', 'trust', 'imtoken', 'pillar'],
			},
			rpc: {
				1: 'https://mainnet.infura.io/v3/',
				// 4: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
				137: 'https://polygon-mainnet.infura.io',
				// 80001: 'https://matic-mumbai.chainstacklabs.com',
				56: 'https://bsc-dataseed.binance.org/',
				97: 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
			},
		},
	},
}

export const web3Modal = new Web3Modal({
	cacheProvider: true,
	providerOptions: providerOptions,
	disableInjectedProvider: false,
})
