import React from 'react'
import classNames from 'classnames'
import './index.less'
import { useAppSelector } from '_src/store/hooks'
import { showShortAddress } from '../../utils/number'
import useWallet from '_src/hooks/useWallet'
import { imgUrl } from '_src/utils/staticUrl'
import Icon from '_src/components/Icon'

const Header = ({ className }) => {
  const { connected, address } = useAppSelector((store) => store.global)
  const { walletConnect, walletDisconnect } = useWallet()
  // 连接
  const handleConnect = async () => {
    const res = await walletConnect().catch((error) => {
      console.log('钱包连接失败', error)
    })
    console.log('是否连接成功', res)
    // if (res?.connected) {
    // 	login()
    // }
  }
  // 断开
  const handleLoginOut = async () => {
    await walletDisconnect().catch((error) => {
      console.log('钱包退出失败', error)
    })
  }

  return (
    <div id={classNames('header', className)}>
      <div className="headerCenter">
        <div className="headerLeft">
          <img src={imgUrl('logo.png')} />
        </div>
        {/* <div className="headerTitle">Lottery</div> */}
        <div className="headerRight">
          {/* <Theme /> */}
          {/* <LanguageSelect /> */}
          {connected && address ? (
            <>
              <div className="walletBtn">
                <Icon type="icon-wallet" className="icon" />
                <div className="loginAddress">{showShortAddress(address, 4)}</div>
                <Icon
                  type="icon-disconnect"
                  className="icons"
                  onClick={() => {
                    handleLoginOut()
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="walletBtn paddingBtn" onClick={handleConnect}>
                Connect Wallet
              </div>
            </>
          )}
        </div>
        <div className="userBox"></div>
      </div>
    </div>
  )
}

export default Header
