import { createAction } from '@reduxjs/toolkit'
export const setTheme = createAction('global/setTheme')
export const setIsSupportNetworkAction = createAction('global/setIsSupportNetworkAction')
import { isAppSupportedNetworkId, isAppSupportedChainId } from '_src/utils/chain'
export const setNetworkIdAction = createAction('global/setNetworkId', (networkId) => {
  if (isAppSupportedNetworkId(networkId)) {
    return { payload: networkId }
  } else {
    return { payload: '' }
  }
})

export const setChainIdAction = createAction('global/setChainId', (chainId) => {
  if (isAppSupportedChainId(chainId)) {
    return { payload: chainId }
  } else {
    return { payload: '' }
  }
})
