// 浅色
import { firepowerLightTheme } from './firepower'

const lightTheme = {
	'--header-bg': '#0C043B',
	'--main-bgc': '#0C043B',
	'--main-font-c': '#B1B1B1',
	'--datePicker-border-c-1': '#333',
	'--datePicker-bgc-1': 'transparent',
	'--select-font-c-1': '#333',
	'--select-font-c-2': '#fff',
	'--select-font-c-3': 'rgb(184, 191, 195)',
	'--select-bgc-1': '#202020',
	'--select-bgc-2': '#eee',
	'--select-border-c-1': '#333',
	'--select-border-c-2': 'rgba(255, 255, 255, 0.19)',
	'--table-font-c-1': '#333',
	'--table-font-c-2': '#666',
	'--table-font-c-3': '#fff',
	'--table-font-c-4': 'transparent',
	'--table-bgc-1': '#333',
	'--table-bgc-2': '#000',
	'--table-bgc-3': '#fff',
	'--table-bgc-4': '#f8f8f8',
	'--table-bgc-5': '#eee',
	'--table-border-c-1': '#000',
	'--table-border-c-2': '#333',
	'--table-border-c-3': 'transparent',
	'--table-border-c-4': '#999',
	'--tooltip-bgc-1': '#333',
	'--paragraph-font-c-1': '#333',
	'--paragraph-font-c-2': '#68BF7B',
	...firepowerLightTheme,
}

export default lightTheme
